<template>
  <!-- eslint-disable max-len -->
  <v-app>
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          lg="4"
        >
          <v-img src="@/assets/img/logo_color.png" />
        </v-col>

        <v-col cols="12">
          <h1 class="text-center"> WAPIDU - TÉRMINOS Y CONDICIONES</h1>
          <br>

          <p>
            <small>
              Fecha de Publicación: 16 de junio de 2023
              <br>
              Fecha de última actualización: 16 de junio de 2023
            </small>
          </p>
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <v-btn
            color="primary"
            block
            @click="close"
          >
            <v-icon left>mdi-close</v-icon>
            Cerrar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'NoticeOfPrivacy',

  methods: {
    close() {
      window.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
